

import React, { useState } from 'react';
import CopyClipIcon from './CopyClipIcon';

const CopyWithPopup = ({ text, type }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const containerStyle = {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
  };

  const textContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: 'white',
    border: '1px solid #e2e8f0',
    borderRadius: '0.375rem',
    padding: '0.5rem 1rem',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    transition: 'all 0.2s ease-in-out',
  };

  const textStyle = {
    color: '#4a5568',
    fontWeight: 500,
    marginRight: '0.5rem',
  };

  const iconContainerStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  };

  const popupStyle = {
    position: 'absolute',
    top: '50%',
    left: 'calc(100% + 8px)',
    transform: 'translateY(-50%)',
    padding: '0.25rem 0.5rem',
    backgroundColor: '#4a5568',
    color: 'white',
    fontSize: '0.75rem',
    borderRadius: '0.25rem',
    boxShadow: '0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.06)',
    zIndex: 9999,
    whiteSpace: 'nowrap',
    animation: 'fadeIn 0.2s ease-out forwards',
  };

  const popupArrowStyle = {
    content: '""',
    position: 'absolute',
    top: '50%',
    right: '100%',
    transform: 'translateY(-50%)',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: 'transparent #4a5568 transparent transparent',
  };

  return (
    <div style={containerStyle}>
      <div 
        // style={textContainerStyle}
        // onMouseEnter={(e) => {
        //   e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
        // }}
        // onMouseLeave={(e) => {
        //   e.currentTarget.style.boxShadow = '0 1px 2px 0 rgba(0, 0, 0, 0.05)';
        // }}
      >
       
          <CopyClipIcon
            onClick={handleCopy}
            style={{
              color: '#a0aec0',
              transition: 'color 0.2s ease-in-out',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = '#4299e1';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = '#a0aec0';
            }}
            size={20}
          />
          {showPopup && (
            <div style={popupStyle}>
              <span style={{ fontWeight: 500 }}>Copied!</span>
              <div style={popupArrowStyle}></div>
            </div>
          )}
       
      </div>
    </div>
  );
};

export default CopyWithPopup;

// import React from 'react';
// import CopyClipIcon from './CopyClipIcon';
// import { useState } from 'react';

// const CopyWithPopup = ({ text, type }) => {
//     const [showPopup, setShowPopup] = useState(false);
  
//     const handleCopy = async () => {
//       try {
//         await navigator.clipboard.writeText(text);
//         setShowPopup(true);
//         setTimeout(() => setShowPopup(false), 2000); 
//       } catch (err) {
//         console.error('Failed to copy text: ', err);
//       }
//     };
  
//     return (
//       <>
//         <style>
//           {`
//             .copy-container {
//               position: relative;
//               display: inline-flex;
//               align-items: center;
//             }
//             .copy-text {
//               margin-right: 8px;
//               font-size: 16px;
//               color: #333;
//             }
//             .copy-icon {
//               color: #666;
//               transition: color 0.3s ease;
//             }
//             .copy-icon:hover {
//               color: #333;
//             }
//             .popup {
//               position: absolute;
//               top: 100%;
//               left: 50%;
//               transform: translateY(-50%);
//               margin-top: 8px;
//               padding: 8px 16px;
//               background-color: #333;
//               color: #fff;
//               font-size: 14px;
//               border-radius: 4px;
//               box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//               white-space: nowrap;
//             }
//           `}
//         </style>
//         <div className="copy-container">
//           <CopyClipIcon onClick={handleCopy} className="copy-icon" />
//           {showPopup && <div className="popup">Copied</div>}
//         </div>
//       </>
//     );
//   };
  
//   export default CopyWithPopup;