import React from 'react';
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const RequireUnauthRoute = ({
    component: Component,
    authenticated,
    ...rest
}) => {
    const { cognitoUser } = useSelector((state) => state.auth);
    const storedDinNumber = localStorage.getItem('din_number');
    const urlParams = new URLSearchParams(window.location.search);
    const dinNumber = urlParams.get('din_number');
    console.log("urlParams", dinNumber)
  
    if (dinNumber) {
      localStorage.setItem('din_number', dinNumber);
      console.log('DIN number stored in localStorage:', dinNumber);
    } else {
      console.log('DIN number not found in URL.');
    }

    return (
        <>
            {storedDinNumber ?
                <Route
                    {...rest}
                    render={(props) => {
                        return authenticated ? (
                            <Redirect
                                to={{
                                    pathname: "/din",
                                    state: { from: props.location },
                                }}
                            />
                        ) : (
                            <>
                                {authenticated ? (
                                    <Redirect
                                        to={{
                                            pathname: "/din",
                                            state: { from: props.location },
                                        }}
                                    />
                                ) : (
                                    <Component {...props} />
                                )}
                            </>
                        );
                    }}
                /> : <Route
                    {...rest}
                    render={(props) => {
                        return authenticated ? (
                            <Redirect
                                to={{
                                    pathname: "/home",
                                    state: { from: props.location },
                                }}
                            />
                        ) : (
                            <>
                                {authenticated ? (
                                    <Redirect
                                        to={{
                                            pathname: "/home",
                                            state: { from: props.location },
                                        }}
                                    />
                                ) : (
                                    <Component {...props} />
                                )}
                            </>
                        );
                    }}
                />
            }
        </>
    );
};

export default RequireUnauthRoute;
