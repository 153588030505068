import {
    API_USAGE_FAILURE,
    CANCEL_CHANGE_PLAN,
    CANCEL_CHANGE_PLAN_FAILURE,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_FAILURE,
    CHANGE_PLAN,
    CHANGE_PLAN_FAILURE,
    CHECKOUT_DETAIL_FAILURE,
    CURRENT_PLAN_FAILURE,
    ESTIMATE_REQ_COST_PRICE,
    ESTIMATE_REQ_COST_PRICE_FAILURE,
    FIRST_POPUP,
    PLANDETAIL_FAILURE,
    PROFILE_DETAIL_FAILURE,
    REMOVE_CANCEL_SCHEDULE,
    REMOVE_CANCEL_SCHEDULE_FAILURE
} from 'getDetails'
import {
    BILLING_DETAIL,
    CHECKOUT_DETAIL,
    CLIENTS_LIST,
    CLIENT_DETAILS,
    INITIAL_CHECKOUT,
    PLANDETAIL,
    PLAN_ID,
    AUTH_LOG,
    AUTH_LOG_FAILURE,
    BILLING_DETAIL_FAILURE,
    PROFILE_DETAIL,
    CURRENT_PLAN,
    API_USAGE,
    LEADS_EXTRACTED,
    LEADS_EXTRACTED_FAILURE,
    GET_TOTAL_CREDITS,
    GET_EMAIL_PHONE,
    GET_EMAIL_PHONE_FAILURE,
    LINKEDIN_REQUESTS,
    LINKEDIN_REQUESTS_FAILURE,
    CREATE_TEAM,
    CREATE_TEAM_FAILURE,
    DELETE_TEAM,
    DELETE_TEAM_FAILURE,
    ADD_TEAM_MEMBER,
    ADD_TEAM_MEMBER_FAILURE,
    REMOVE_TEAM_MEMBER,
    REMOVE_TEAM_MEMBER_FAILURE,
    GET_ALL_TEAMS,
    GET_ALL_TEAMS_FAILURE,
    GET_ALL_REACHCOMPANY,
    GET_ALL_REACHCOMPANY_FAILURE,
    GET_ALL_REACHDIRECTOR,
    GET_ALL_REACHDIRECTOR_FAILURE,
    GET_COMPANY_DETAILS,
    GET_COMPANY_DETAILS_FAILURE,
    GET_DIRECTOR_DETAILS_FAILURE,
    GET_DIRECTOR_DETAILS,
    SET_MODAL_LOADER,
    DETAILS_LOADER,
    COMPANY_DETAILS_LOADER,
    GET_TEAM_FAILURE,
    GET_TEAM,
    SET_STATUS_MESSAGE,
    GET_TEAM_MEMBER_DETAILS,
    GET_TEAM_MEMBER_DETAILS_FAILURE,
    INCREMENT_ALLOTTED_CREDITS_FAILURE,
    INCREMENT_ALLOTTED_CREDITS,
    REPORT_LINKEDIN_PROFILE_FAILURE,
    REPORT_LEAD_EXTRACTED_FAILURE,
    GET_USER_DETAILS,
    GET_USER_DETAILS_FAILURE,
    GET_USER_TEAMS_DETAILS,
    GET_USER_TEAMS_DETAILS_FAILURE,
    GET_RECHARGE_HISTORY,
    GET_RECHARGE_HISTORY_FAILURE,
    GET_LINKEDIN_BULK_REQUESTS,
    GET_LINKEDIN_BULK_REQUESTS_FAILURE,
    SUBMIT_LINKEDIN_BULK_REQUEST,
    SUBMIT_LINKEDIN_BULK_REQUEST_FAILURE,
    GET_RESOLVED_LINKEDIN_BULK_REQUEST,
    GET_RESOLVED_LINKEDIN_BULK_REQUEST_FAILURE,
    GET_EMAIL_PHONE_GST,
    GET_EMAIL_PHONE_FAILURE_GST
} from './action-types'
import {
    getClientsListCall,
    getClientDetailsCall,
    getPlanListDetails,
    checkOutPlan,
    getBillingDetail,
    getApiUsage,
    getClientInfo,
    getAuthLogs,
    getCurrentPlan,
    cancelSubscription,
    changePlanAPI,
    cancelScheduleChangePlanAPI,
    removeCancelScheduleAPI,
    estimatePriceApi,
    getLeadsExtractedCall,
    getMobileEmailFromDinCall,
    getMobileEmailFromGSTCall,
    getLinkedinRequestsCall,
    createTeamCall,
    deleteTeamCall,
    addTeamMemberCall,
    removeTeamMemberCall,
    getAllTeamsCall,
    searchCompanyServerCall,
    searchDirectorServerCall,
    getCompanyDetailsCall,
    getDirectorDetailsCall,
    getTeamCall,
    getTeamMemberDetailsCall,
    incrementAllottedCreditsCall,
    reportLinkedinProfileCall,
    reportLeadExtractedCall,
    getUserDetailsCall,
    getUserTeamDetailsCall,
    getRechargeHistoryCall,
    getLinkedinBulkRequestCall,
    submitLinkedinBulkRequestCall,
    getResolvedLinkedinBulkRequestCall
} from './manager'

export function getClientsList() {
    return (dispatch) => getClientsListCall()
        .then((result) => {
            dispatch({
                type: CLIENTS_LIST, payload: result.data
            })
        })
        .catch((e) => {
            // console.log("error");
        })
}

export function getClientDetails(data) {
    return (dispatch) => getClientDetailsCall(data)
        .then((result) => {
            dispatch({
                type: CLIENT_DETAILS, payload: result.data
            })
        })
        .catch((e) => {
            // console.log(e);
        })
}

export function getPlanList() {
    return (dispatch) => {
        // console.log("Called");
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
        getPlanListDetails()
            .then((result) => {
                dispatch(setPlanDetails(result.data))
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
            .catch((e) => {
                dispatch({
                    type: PLANDETAIL_FAILURE, payload: e.response
                })
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
    }
}

export function checkOut(id) {
    // console.log("ID", id);
    return (dispatch) => {
        checkOutPlan(id)
            .then((result) => dispatch(setCheckout(result)))
            .catch((e) => dispatch({
                type: CHECKOUT_DETAIL_FAILURE, payload: e.response
            }))
    }
}

export const setPlanDetails = (payload) => {
    return {
        type: PLANDETAIL, payload: payload.data
    }
}
export const setCheckout = (payload) => {
    // console.log("Action", payload);
    return {
        type: CHECKOUT_DETAIL, payload: payload.data
    }
}

export function setInitialCheckout(payload) {
    // console.log("Initial Payload", payload);
    return (dispatch) => dispatch({
        type: INITIAL_CHECKOUT, payload
    })
}

export function getPlanID(payload) {
    // console.log("Chexckout id", payload);
    return (dispatch) => {
        dispatch({
            type: PLAN_ID, payload
        })
    }
}

export function getBilling() {
    return (dispatch) => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
        getBillingDetail()
            .then((result) => {
                dispatch({
                    type: BILLING_DETAIL, payload: result.data
                })
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
            .catch((e) => {
                dispatch({
                    type: BILLING_DETAIL_FAILURE, payload: e.response
                })
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
    }
}

export function getAuthLogData() {
    return (dispatch) => {
        getAuthLogs()
            .then((result) => {
                // console.log("AUTHLOG", result);
                dispatch({
                    type: AUTH_LOG, payload: result.data
                })
            })
            .catch((e) => dispatch({
                type: AUTH_LOG_FAILURE, payload: e.response
            }))
    }
}

export const getProfileDetails = () => (dispatch) => {
    getClientInfo()
        .then((result) => dispatch({
            type: PROFILE_DETAIL, payload: result.data
        }))
        .catch((e) => dispatch({
            type: PROFILE_DETAIL_FAILURE, payload: e.response
        }))
}

export const getCurrentPlanDetails = (a) => (dispatch) => {
    getCurrentPlan()
        .then((result) => {
            dispatch({
                type: CURRENT_PLAN, payload: result.data
            })
        })
        .catch((e) => dispatch({
            type: CURRENT_PLAN_FAILURE, payload: e.response
        }))
}
export const getApiUsageDetail = () => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    // dispatch({
    //   type: API_USAGE,
    //   payload: []
    // })
    getApiUsage()
        .then((result) => dispatch({
            type: API_USAGE, payload: result.data.data
        }))
        .catch((e) => dispatch({
            type: API_USAGE_FAILURE, payload: e.response
        }))
        .finally(() => {
            dispatch({
                type: DETAILS_LOADER, payload: false
            })
        })
}

export const getRechargeHistory = () => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    getRechargeHistoryCall()
        .then((res) => {
            dispatch({
                type: GET_RECHARGE_HISTORY, payload: res.data.data
            })
        })
        .catch((err) => {
            dispatch({
                type: GET_RECHARGE_HISTORY_FAILURE, payload: err?.response?.data?.message || err?.message
            })
        })
        .finally(() => {
            dispatch({
                type: DETAILS_LOADER, payload: false
            })
        })
}
export const getLeadsExtracted = () => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    dispatch({
        type: LEADS_EXTRACTED, payload: null
    })
    getLeadsExtractedCall()
        .then((result) => dispatch(setLeadsExtracted(result.data.data))).catch((err) => dispatch({
        type: LEADS_EXTRACTED_FAILURE, payload: err?.response?.data?.message || err?.message
    })).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const setLeadsExtracted = (payload) => dispatch => {
    dispatch({
        type: LEADS_EXTRACTED, payload: payload
    })
}

export const getLinkedinRequests = () => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    getLinkedinRequestsCall().then((result) => dispatch(setlinkedinRequests(result.data.data))).catch((err) => dispatch({
        type: LINKEDIN_REQUESTS_FAILURE, payload: err?.response?.data?.message
    })).finally(() => dispatch({
        type: DETAILS_LOADER, payload: false
    }))
}
export const getLinkedinBulkRequests = () => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    getLinkedinBulkRequestCall().then((res) => {
        dispatch({
            type: GET_LINKEDIN_BULK_REQUESTS, payload: res.data.data
        })
    }).catch(err => {
        dispatch({
            type: GET_LINKEDIN_BULK_REQUESTS_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const submitLinkedinBulkRequest = (data) => (dispatch) => {
    dispatch({
        type: SET_MODAL_LOADER, payload: true
    })
    submitLinkedinBulkRequestCall(data).then((res) => {
        dispatch(setStatusMessage(res.data.message))
    }).catch((err) => {
        dispatch({
            type: SUBMIT_LINKEDIN_BULK_REQUEST_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: SET_MODAL_LOADER, payload: false
        })
    })
}

export const getResolvedLinekedinBulkRequest = (data) => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    getResolvedLinkedinBulkRequestCall(data).then((res) => {
        const data = res.data.data
        dispatch(setLinkedinBulkRequestDownloadUrl(data))
    }).catch((err) => {
        dispatch({
            type: GET_RESOLVED_LINKEDIN_BULK_REQUEST_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const setLinkedinBulkRequestDownloadUrl = (data) => (dispatch) => {
    dispatch({
        type: GET_RESOLVED_LINKEDIN_BULK_REQUEST, payload: data
    })
}

export const setlinkedinRequests = (payload) => (dispatch) => {
    dispatch({
        type: LINKEDIN_REQUESTS, payload: payload
    })
}


export const getPhoneEmailFromDin = (data) => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    dispatch({
        type: GET_EMAIL_PHONE, payload: null
    })
    getMobileEmailFromDinCall(data).then((result) => {
        const data = result.data.data
        dispatch({
            type: GET_EMAIL_PHONE, payload: data
        })
    }).catch((err) => {
        dispatch({
            type: GET_EMAIL_PHONE_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const getPhoneEmailFromGST = (data)  =>dispatch=>{
    dispatch({
        type: DETAILS_LOADER, payload: true
    });
    dispatch({
        type: GET_EMAIL_PHONE_GST, payload: null
    });
    getMobileEmailFromGSTCall(data).then((result) => {
        console.log("result",result);
        const payload = result
        dispatch({
            type: GET_EMAIL_PHONE_GST, payload: payload
        });
    }).catch((error) => {
        console.log("Error caught:", error); 
        dispatch({
            type: GET_EMAIL_PHONE_FAILURE_GST, 
            payload: error?.response?.data?.message || "An error occurred while fetching the email/phone from GST."
        });
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    });
};



export const getUserDetails = () => (dispatch) => {
    // dispatch({
    //   type: DETAILS_LOADER,
    //   payload: true
    // })
    getUserDetailsCall().then((res) => {
        const data = res.data.data
        dispatch({
            type: GET_USER_DETAILS, payload: data
        })
    }).catch((err) => {
        dispatch({
            type: GET_USER_DETAILS_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        // dispatch({
        //   type: DETAILS_LOADER,
        //   payload: false
        // })
    })
}

export const getUserTeamDetails = (data) => dispatch => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    getUserTeamDetailsCall(data).then((res) => {
        dispatch({
            type: GET_USER_TEAMS_DETAILS, payload: res.data
        })
    }).catch((err) => {
        dispatch({
            type: GET_USER_TEAMS_DETAILS_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}


export const setStatusMessage = (payload) => dispatch => {
    dispatch({
        type: SET_STATUS_MESSAGE, payload: payload
    })
}

export const reportLeadExtracted = (data) => dispatch => {
    dispatch({
        type: SET_MODAL_LOADER, payload: true
    })
    reportLeadExtractedCall(data).then((result) => {
        dispatch(setStatusMessage(result.data.message))
    }).catch((err) => {
        dispatch({
            type: REPORT_LEAD_EXTRACTED_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: SET_MODAL_LOADER, payload: false
        })
    })
}

export const reportLinkedinProfile = (data) => dispatch => {
    dispatch({
        type: SET_MODAL_LOADER, payload: true
    })
    reportLinkedinProfileCall(data).then((result) => {
        dispatch(setStatusMessage(result.data.message))
    }).catch((err) => {
        dispatch({
            type: REPORT_LINKEDIN_PROFILE_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: SET_MODAL_LOADER, payload: false
        })
    })
}

export const setCurrentTeamData = payload => dispatch => {
    dispatch({
        type: GET_TEAM, payload: payload
    })
}

export const getTeam = (data) => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    getTeamCall(data).then((result) => {
        dispatch(setCurrentTeamData(result.data.data))
        // dispatch(setStatusMessage(result.data.message))
    }).catch((err) => {
        dispatch({
            type: GET_TEAM_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const getAllTeams = () => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    getAllTeamsCall().then((result) => {
        dispatch(setAllTeams(result.data.data))
    }).catch((err) => {
        dispatch({
            type: GET_ALL_TEAMS_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const getAllSurereachCompany = (companySearch) => (dispatch) => {
    dispatch({ type: DETAILS_LOADER, payload: true });

    searchCompanyServerCall(companySearch)
        .then((result) => {
            dispatch({
                type: GET_ALL_REACHCOMPANY,
                payload: result.data
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_ALL_REACHCOMPANY_FAILURE,
                payload: err?.response?.data?.message || "Failed to fetch data"
            });
        })
        .finally(() => {
            dispatch({ type: DETAILS_LOADER, payload: false });
        });
};

export const getAllDirectorCompany = (directorSearch) => (dispatch) => {
    dispatch({ type: DETAILS_LOADER, payload: true });

    searchDirectorServerCall(directorSearch)
        .then((result) => {
            dispatch({
                type: GET_ALL_REACHDIRECTOR,
                payload: result.data
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_ALL_REACHDIRECTOR_FAILURE,
                payload: err?.response?.data?.message || "Failed to fetch data"
            });
        })
        .finally(() => {
            dispatch({ type: DETAILS_LOADER, payload: false });
        });
};


export const getCompanyDetailsApi = (detail) => (dispatch) => {
    dispatch({ type: COMPANY_DETAILS_LOADER, payload: true });
    getCompanyDetailsCall(detail)
        .then((result) => {
            console.log("result",result)
            dispatch({
                type: GET_COMPANY_DETAILS,
                payload: result.data
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_COMPANY_DETAILS_FAILURE,
                payload: err?.response?.data?.message || "Failed to fetch data"
            });
        })
        .finally(() => {
            dispatch({ type: COMPANY_DETAILS_LOADER, payload: false });
        });
};

export const getDirectorDetailsCallApi = (detail) => (dispatch) => {
    dispatch({ type: DETAILS_LOADER, payload: true });
    getDirectorDetailsCall(detail)
        .then((result) => {
            dispatch({
                type: GET_DIRECTOR_DETAILS,
                payload: result
            });
        })
        .catch((err) => {
            dispatch({
                type: GET_DIRECTOR_DETAILS_FAILURE,
                payload: err?.response?.data?.message || "Failed to fetch data"
            });
        })
        .finally(() => {
            dispatch({ type: DETAILS_LOADER, payload: false });
        });
};



export const setAllTeams = payload => dispatch => {
    dispatch({
        type: GET_ALL_TEAMS, payload: payload
    })
}

export const createTeam = (data) => (dispatch) => {
    dispatch({
        type: SET_MODAL_LOADER, payload: true
    })
    createTeamCall(data).then((result) => {
        dispatch(setStatusMessage(result.data.message))
        dispatch({
            type: CREATE_TEAM, payload: result.data.data
        })
    }).catch((err) => {
        dispatch({
            type: CREATE_TEAM_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: SET_MODAL_LOADER, payload: false
        })
    })
}

export const deleteTeam = (data) => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    deleteTeamCall(data).then((result) => {
        dispatch(setStatusMessage(result.data.message))
        dispatch({
            type: DELETE_TEAM, payload: result.data.data
        })
    }).catch((err) => {
        dispatch({
            type: DELETE_TEAM_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const setDeleteTeamStatus = (payload) => dispatch => {
    dispatch({
        type: DELETE_TEAM, payload: payload
    })
}


export const addTeamMember = (data) => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    addTeamMemberCall(data).then((result) => {
        dispatch(setStatusMessage(result.data.message))
        dispatch({
            type: ADD_TEAM_MEMBER, payload: result.data.data
        })
    }).catch((err) => {

        dispatch({
            type: ADD_TEAM_MEMBER_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}


export const removeTeamMember = (data) => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    removeTeamMemberCall(data).then((result) => {
        dispatch(setStatusMessage(result.data.message))
        dispatch({
            type: REMOVE_TEAM_MEMBER, payload: result.data.data
        })
    }).catch((err) => {
        dispatch({
            type: REMOVE_TEAM_MEMBER_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const setCurrentTeamMemberData = (payload) => {
    return {
        type: GET_TEAM_MEMBER_DETAILS, payload: payload?.data
    }
}

export const getTeamMemberDetails = (data) => dispatch => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    getTeamMemberDetailsCall(data).then((result) => {
        dispatch(setStatusMessage(result.data.message))
        dispatch(setCurrentTeamMemberData(result.data))
    }).catch((err) => {
        dispatch({
            type: GET_TEAM_MEMBER_DETAILS_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const incrementAllottedCredits = (data) => (dispatch) => {
    dispatch({
        type: DETAILS_LOADER, payload: true
    })
    incrementAllottedCreditsCall(data).then((result) => {
        const {member_id, team_id} = data;
        dispatch(setStatusMessage(result.data.message))
        dispatch(getTeamMemberDetails({member_id, team_id}))
        dispatch(getUserDetails())
        dispatch({
            type: INCREMENT_ALLOTTED_CREDITS, payload: result.data
        })
    }).catch((err) => {
        dispatch({
            type: INCREMENT_ALLOTTED_CREDITS_FAILURE, payload: err?.response?.data?.message
        })
    }).finally(() => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
    })
}

export const cancelSubscriptionPlan = () => (dispatch) => {
    cancelSubscription()
        .then((result) => dispatch({
            type: CANCEL_SUBSCRIPTION, payload: result.data
        }))
        .catch((e) => dispatch({
            type: CANCEL_SUBSCRIPTION_FAILURE, payload: e.response
        }))
}

export function firstShow(value) {
    return (dispatch) => {
        dispatch({
            type: FIRST_POPUP, payload: value
        })
    }
}

export function changePlan(value) {
    return (dispatch) => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
        changePlanAPI(value)
            .then((res) => {
                dispatch({
                    type: CHANGE_PLAN, payload: res.data
                })
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
            .catch((err) => {
                dispatch({
                    type: CHANGE_PLAN_FAILURE, payload: err?.response?.data?.message
                })
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
    }
}

export function removeCancelSchedule(value) {
    return (dispatch) => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
        removeCancelScheduleAPI(value)
            .then((res) => {
                dispatch({
                    type: REMOVE_CANCEL_SCHEDULE, payload: res.data
                })
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
            .catch((err) => {
                dispatch({
                    type: REMOVE_CANCEL_SCHEDULE_FAILURE, payload: err?.response
                })
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
    }
}

export function removeChangePlan(value) {
    return (dispatch) => {
        dispatch({
            type: DETAILS_LOADER, payload: false
        })
        cancelScheduleChangePlanAPI(value)
            .then((res) => {
                dispatch({
                    type: CANCEL_CHANGE_PLAN, payload: res.data
                })
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
            .catch((err) => {
                dispatch({
                    type: CANCEL_CHANGE_PLAN_FAILURE, payload: err?.response?.data?.message
                })
                dispatch({
                    type: DETAILS_LOADER, payload: true
                })
            })
    }
}


export function getReqCostPrice(value) {
    return dispatch => {
        estimatePriceApi(value).then(res => {
            dispatch({
                type: ESTIMATE_REQ_COST_PRICE, payload: res.data
            })
        }).catch(err => {
            dispatch({
                type: ESTIMATE_REQ_COST_PRICE_FAILURE, payload: err?.response?.data?.message
            })
            // console.log( err?.response?.data?.message)
        })
    }
}
