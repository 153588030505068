import { useEffect, useRef } from "react";

const useEffectDidUpdate = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useEffectDidUpdate;



export const formatColumnName = (columnName) => {
  // Split the column name by underscore or camelCase
  const words = columnName.split(/(?=[A-Z])|_/).map(word => {
    // Capitalize first letter, make rest lowercase
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the words with a space
  return words.join(' ');
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
