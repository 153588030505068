import React, { useEffect, useState } from 'react';
import './collapsibleTable.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import MobileResponsiveTable from './MobileResponsiveTable';
import CopyClipIcon from 'views/components/CopyClipIcon';
import NoDataFound from 'views/components/NoDataFound';
import { Snackbar } from '@material-ui/core';
import CopyWithPopup from 'views/components/CopyWithPopup';
const CollapsibleTable = ({ headers=[], data=[], detailsData , select}) => {
  console.log("selectselect" , data);
  
const formatColumnName = (columnName) => {
  // Split the column name by underscore or camelCase
  const words = columnName.split(/(?=[A-Z])|_/).map(word => {
    // Capitalize first letter, make rest lowercase
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the words with a space
  return words.join(' ');
};

const downloadCSV = (tableData, tableHeaders, fileName) => {
  const csvContent = [
    tableHeaders.join(','),
    ...tableData.map((row) => tableHeaders.map((header) => row[header]).join(',')),
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
  
  const [expandedRow, setExpandedRow] = useState(null);
  const [loadingRow, setLoadingRow] = useState(null);
  const [rowDetails, setRowDetails] = useState({});
  const [styleCheack, setstyleCheack] = useState(false);
  const { loader, reachData, companyDetailData, directorDetailData, reachDirectorData, loading } = useSelector((state) => state.clientData);
  const { emailPhoneDetails, errormsg } = useSelector((state) => state.planReducer,);

  const toggleRow = async (rowIndex, row) => {
    console.log("Row", rowIndex);
    
    if (expandedRow === rowIndex) {
      setExpandedRow(null);
    } else {
      setLoadingRow(rowIndex);
      try {
        const details = await detailsData(data[rowIndex]);
        setstyleCheack(true)
        console.log("detailsdetails", details);
  
        setRowDetails((prev) => ({ ...prev, [rowIndex]: details }));
        setExpandedRow(rowIndex);
      } catch (error) {
        console.error("Error fetching details:", error);
        // Optionally, handle error state
        setLoadingRow(null);
      } finally {
        setLoadingRow(null);
      }
    }
  };


  const CopyableCell = ({ value, isCopyable }) => {
   
    return (
      <div  style={{display:"flex" , justifyContent:"space-between", alignItems:"center"}}>
        <span className='main-table-data' style={{marginRight:"10px"}}>{value}</span>
        {(isCopyable && value) && (

          <CopyWithPopup text={value} type="mobile" />
          // <CopyClipIcon  onClick={handleCopy}/>
        )}
      </div>
    );
  };
  

  const renderDetailTable = (tableData=[], title, fileName) =>{ 
     console.log("tableData",tableData);
    return(
    <div className="detail-table-wrapper">
      <div className="detail-table-header">
        <h3 className="details-title">{title}</h3>
        <button 
          className="download-button" 
          onClick={() => downloadCSV(tableData.rows, tableData.headers, fileName)}
        >
          Download CSV
        </button>
      </div>
      <div className="table-scroll-container">
      { tableData?.rows?.length>0?  <table className="details-table">
          <thead>
            <tr>
              {tableData?.headers?.map((header, index) => (
              <th key={index}> <span className='main-table-heading'> {formatColumnName(header)}</span></th>
              ))}
            </tr>
          </thead>
          <tbody>
           
           {  tableData?.rows?.map((detailRow, detailIndex) => (
              <tr key={detailIndex}>
                {tableData?.headers?.map((header, cellIndex) =>{ 
                  
                // (
                //   <td key={cellIndex}>
                //    <span className='main-table-data'>
                //    {detailRow[header]}
                //       </span>
                //   </td>
                // )

                const isCopyable = ['email', 'phone_no'].includes(header)  && 
                detailRow["email"] !== null && 
                detailRow["phone_no"] !== undefined;
              
              return (
                <td key={cellIndex} >
                  <CopyableCell 
                    value={detailRow[header]} 
                    isCopyable={isCopyable} 
                  />
                </td>)
                
                
                })}
              </tr>
            ))}
          </tbody>
        </table>

:
<NoDataFound/>
}
      </div>
    </div>
    )

};







  return (
    <div className="table-container">
        <div className="main-table-header">
        <h2 className="main-table-title">Director Data</h2>
        <button 
          className="download-button" 
          onClick={() => downloadCSV(data, headers, 'employee_directory.csv')}
        >
          Download CSV
        </button>
      </div>
      <div className="table-scroll-container">
        { data.length>0? <table className="collapsible-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}> <span className='main-table-heading'> {formatColumnName(header)}</span></th>
              ))}
              <th className="actions-column">Actions</th>
            </tr>
          </thead>
           <tbody>
             {  data.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr className={expandedRow === rowIndex ? 'expanded' : ''}>
                  {headers?.map((header, cellIndex) => (
                     <td key={cellIndex}>
                     { <span className='main-table-data'>
                        {row[header] ?? "Not Found"}
                      </span>  }
                    </td>
                  ))}
                  <td className="actions-column">
                    <button
                      className="details-button"
                      onClick={() => toggleRow(rowIndex, row)}
                      aria-expanded={expandedRow === rowIndex}
                      aria-controls={`details-${rowIndex}`}
                      disabled={loadingRow === rowIndex}
                    >
                      {(loadingRow === rowIndex) ? (
                        <span  style={{width:"100px"}}>Loading...</span>
                      ) : expandedRow === rowIndex ? (
                        <span>Hide</span>
                      ) : (
                        <span style={{width:"100px"}}> Get  Details</span>
                      )}
                    </button>
                  </td>
                </tr>

                {(expandedRow === rowIndex && 
                  rowDetails[rowIndex] && 
                  (!loading || loadingRow !== rowIndex)) && (
                  <tr className="details-row">
                    <td colSpan={headers.length + 1}>
                      <div id={`details-${rowIndex}`} className="details-content">
                        <div className="table-scroll-container">
                          {renderDetailTable(
                            rowDetails[rowIndex].table1, 
                            "Din Details", 
                            `Din_${rowIndex}.csv`
                          )}
                        </div>
                        {/* {showTwoTables(row) && rowDetails[rowIndex].table2 && (
                          <div className="table-scroll-container">
                            {renderDetailTable(
                              rowDetails[rowIndex].table2, 
                              "Table 2", 
                              `employee_${row.ID}_table2.csv`
                            )}
                          </div>
                        )} */}
                         {
                         select=="Director" &&   
                        <div style={{marginTop:"50px"}}>
                          {console.log("MobileResponsiveTable", rowDetails[rowIndex]?.companyDetails ,rowIndex  , rowDetails[rowIndex])}
                         <MobileResponsiveTable
                             data={rowDetails[rowIndex]?.companyDetails}
                             select={select}
                             styleCheack={styleCheack}
                         />
                       </div>

                         }
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))  }
          </tbody>
        </table>
        :
        <NoDataFound/>
      
      }
      </div>


    
    </div>
  );
};


export default CollapsibleTable;