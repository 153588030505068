import React from "react";
import {
    Box,
    Button,
    Grid,
    InputLabel,
    TextField,
    makeStyles, MuiThemeProvider, createMuiTheme,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/styles";
import { CLEAR_ERROR, GET_EMAIL_PHONE } from "getDetails";
import { getPhoneEmailFromDin } from "getDetails/actions";
import { useRef } from "react";
import { useCallback, useEffect, useState } from "react";
import MobileResponsiveTable from "./MobileResponsiveTable";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import CustomSnackbar from "views/components/custom-snackbar";
import FormContainer from "views/containers/form-container/form-container";
import { getAllSurereachCompany, getCompanyDetailsApi, getDirectorDetailsCallApi, getAllDirectorCompany } from "getDetails/actions";
import MUIDataTable from "mui-datatables";
import VisibilityIcon from '@material-ui/icons/Visibility';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import { getMobileEmailFromDinCall } from "getDetails/manager";
import { TableRow, TableCell } from "@material-ui/core"
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import CollapsibleTable from "./CollapsibleTable";
import { getCompanyDetailsCall } from "getDetails/manager";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "50%",
        margin: " 0 auto",
        "& > * + *": {
            marginTop: theme.spacing(0.3),
        },
    },
    main: {
        margin: "20px auto 10px auto",
        width: "50%",
        borderRadius: "1rem",
        padding: "1rem",
    },
    rootBox: {
        margin: "0 auto",
    },
    searchBox: {
        width: "100%",
        padding: "10px 14px",
    },
    searchBar: {
        width: "100%",
    },
    resultBox: {
        width: "95%",
        margin: " auto",
        borderRadius: "3px",
    },
    ul: {
        display: "flex",
        listStyle: "none",
        padding: "10px",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        transition: "all .2s ease",
        "&:hover": {
            backgroundColor: " #e7e5e58f",
        },
    },
    DinDetail: {
        width: "100%",
        margin: "1px auto",
        padding: "4px 8px",
    },
    employeeBox: {
        width: "90%",
        margin: "10px auto",
    },
    paper: {
        padding: "1rem",
        width: "80%",
        margin: "0 auto",
    },
    input: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderColor: "#999",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3f51b5",
            },
        },
    },
    formBox: {
        width: "94%",
        backgroundColor: "#fff",
        justifyContent: "center",
        margin: "0 auto",
    },
    noBorder: {
        border: "none",
    },
    text: {
        textTransform: "capitalize",
    },
    box: {
        padding: "16px",
    },
    heading: {
        fontWeight: "600",
    },
    tokenInput: {
        width: "100%",
        margin: "10px 0 ",
        borderRadius: 5,
        backgroundColor: "#ffffff66",
    },
    tableContainer: {
        width: "90%",
        margin: "20px auto ",
    },

    scrollableContainer: {
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        // padding: theme.spacing(2),
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },

    loaderContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
    },

    linearProgress: {
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.primary.main, // Optional: customize the loader color
        },
        height: '4px', // Optional: customize the loader height
    }

}));

function DinDetail() {
    const SubmitButton = withStyles((theme) => ({
        root: {
            color: "#fff",
            padding: "6px 6px 6px 0",
            width: "10rem",
            fontSize: "14px",
            boxShadow:
                " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
            fontWeight: "800",
            borderRadius: "8px",
            letterSpacing: "1px",
            textTransform: "capitalize",
            backgroundColor: "#221c53",
            "&:hover": {
                background: "#fff",
                color: theme.palette.primary.main,
            },
            "& .MuiTouchRipple-root": {
                opacity: 0.2,
                zIndex: -1,
            },
        },
    }))(Button);
    const SerachButton = withStyles((theme) => ({
        root: {
            color: "#fff",
            padding: "6px 6px 6px 0",
            width: "10rem",
            fontSize: "14px",
            boxShadow:
                " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
            fontWeight: "800",
            borderRadius: "8px",
            letterSpacing: "1px",
            textTransform: "capitalize",
            backgroundColor: "#221c53",
            "&:hover": {
                background: "#fff",
                color: theme.palette.primary.main,
            },
            "& .MuiTouchRipple-root": {
                opacity: 0.2,
                zIndex: -1,
            },
        },
    }))(Button);
    const [dinNum, setDinNum] = useState()
    const [select, setSelect] = useState("Din");
    const collapsibleTableRef = useRef(null);
    const [companyDetailArrayData, setCompanyDetailArrayData] = useState()
    const { loader, reachData, companyDetailData, directorDetailData, reachDirectorData, loading } = useSelector((state) => state.clientData);
    const { emailPhoneDetails, errormsg } = useSelector((state) => state.planReducer,);
    const getMuiTheme = () => createMuiTheme({
        overrides: {
            MuiPaper: {
                root: {
                    width: "90%", margin: "2rem auto",
                },
            }, MUIDataTableToolbar: {
                actions: {
                    // display: "none",
                },
            }, MuiInputBase: {
                formControl: {
                    "&:hover": {
                        outline: "none",
                    },
                },
            }, MuiTableCell: {
                body: {
                    textAlign: "center",
                },
            }, MUIDataTableHeadCell: {
                data: {
                    fontWeight: "600",
                }, contentWrapper: {
                    justifyContent: "center",
                },
            },
        },
    });
    const dispatch = useDispatch();
    const [data, showData] = useState(false);
    const [dinNumber, setdinNumber] = useState("");
    const [companySearch, setCompanySearch] = useState("")
    const [dinDetail, setDinDetail] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [errorMesg, setErrorMesg] = useState("");
    const [dinError, showdinError] = useState(false);
    const [checked, setCheck] = useState(false);
    const classes = useStyles();
    let regex = /^[0-9]*$/;

    function handleRadioChange(e) {
        showdinError("");
        setSelect(e.target.value)
        setDinDetail(null)
        setCompanySearch("")
        setCompanyDetailArrayData()
        setdinNumber("")
        dispatch({ type: "RESET_STATE" });  
    }

    const searchcolumns = [
        {
            name: "identification_number",
            label: "CIN",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "company_name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "company_address",
            label: "Company Address",
            options: {
                filter: true,
                sort: true,
            }
        }
    ]
 
    const columns = [
        "Full Name",
        "Father Name",
        "Date Of Birth",
        "Present Address",
        "Permanent Address",
        "Nationality",
        "Email",
        "Phone",
        "Status",
    ];

    const rows = [
        [
            dinDetail?.full_name,
            dinDetail?.father_name,
            dinDetail?.dob,
            dinDetail?.present_address,
            dinDetail?.permanent_address,
            dinDetail?.nationality,
            dinDetail?.email,
            dinDetail?.phone_no,
            dinDetail?.status,
        ],
    ];

    const handleChange = (e) => {
    let value = e?.target?.value || '';    
    if (select === "Din") {
        if (!regex.test(value)) {
            showdinError("DIN Number should only be numeric");
            setdinNumber('');
        } else {
            setdinNumber(value);
         
            showdinError(false);
        }
    } else {
        setCompanySearch(value);
        showData(false);
        setCheck(false);
    }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (select == "Din") {
            if (!dinNumber ) {
                showdinError("Please enter DIN Number");
            } else if (dinNumber.length != 8) {
                showdinError("DIN length should be 8 characters");
            } else if (Number(dinNumber)) {
                const data = {
                    director_id: dinNumber,
                };
                dispatch(getPhoneEmailFromDin(data));
                localStorage.removeItem('din_number');
            }
        }
        else {
            dispatch({ type: "RESET_STATE" });  
            setCompanyDetailArrayData()

            if (select === "Company") {
                if (!companySearch) {
                    showdinError("Please enter Company Name");
                    return
                }
                dispatch(getAllSurereachCompany(companySearch))
            } else if (select === "Director") {
                if (!companySearch) {
                    showdinError("Please enter Director Name");
                    return
                }
                dispatch(getAllDirectorCompany(companySearch))
            }


        }
    };

    const handleRowClicked = (e) => {
        setDinDetail(null)
        let payload = e[0]
        dispatch(getCompanyDetailsApi(payload))
        dispatch(getDirectorDetailsCallApi(payload))
    }

    const headers = select === "Company" ? ['din_number', 'first_name', 'designation', 'current_designation_date', "email_address", "mobile_no"] : ["din_number", "identification_number", "first_name", "company_name"];


    const detailsData = async (rowData) => {
        const payload = { director_id: rowData.din_number };
        try {
            let emailPhoneDetails;
            let companyDetailsResponse;
            if (select === "Company") {
                const response = await getMobileEmailFromDinCall(payload);
                emailPhoneDetails = response?.data?.data || [];
            } else if (select === "Director") {
                const [response, companyDetails] = await Promise.allSettled([
                    getMobileEmailFromDinCall(payload),
                    getCompanyDetailsCall(rowData?.identification_number)
                ]);
                emailPhoneDetails = response?.value?.data?.data || [];
                companyDetailsResponse = companyDetails?.value?.data?.data || [];
            } else {
                throw new Error('Invalid selection');
            }

            // Centralized result generation
            const result = {
                table1: {
                    headers: [
                        'full_name', 'father_name', 'present_address',
                        'nationality', 'email', 'phone_no', 'status', 
                    ],
                    rows: [{
                        full_name: emailPhoneDetails?.full_name || '',
                        father_name: emailPhoneDetails?.father_name || '',
                        present_address: emailPhoneDetails?.present_address || '',
                        permanent_address: emailPhoneDetails?.permanent_address || '',
                        nationality: emailPhoneDetails?.nationality || '',
                        email: emailPhoneDetails?.email || '',
                        phone_no: emailPhoneDetails?.phone_no || '',
                        status: emailPhoneDetails?.status || '',
                        designation: emailPhoneDetails?.designation || '',
                    }]
                }
            };

            // Add companyDetailsResponse to the return object if not a Company selection
            return select === "Company"
                ? result
                : {
                    ...result,
                    companyDetails: companyDetailsResponse
                };

        } catch (error) {
            console.error('Error fetching details:', error);

            return {
                table1: {
                    headers: [
                        'full_name', 'father_name', 'present_address', 'permanent_address',
                        'nationality', 'email', 'phone_no', 'status', 'designation'
                    ],
                    rows: null
                }
            };
        }
    };

    useEffect(() => {
        setErrorMesg(errormsg);
    }, [errormsg]);

    useEffect(() => {
        if (emailPhoneDetails) {
            emailPhoneDetails.present_address =
                emailPhoneDetails?.present_address?.replaceAll("$", ", ");
        }
        setDinDetail(emailPhoneDetails);
        showData(emailPhoneDetails);
    }, [emailPhoneDetails]);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        console.log(window.location.pathname + window.location.search);
        return () => {
            dispatch({ type: GET_EMAIL_PHONE });
            setDinDetail({});
        };
    }, []);

    useEffect(() => {
        const storedDinNumber = localStorage.getItem('din_number');
        if (storedDinNumber) {
            setdinNumber(storedDinNumber);
            localStorage.removeItem('din_number');
        }
    }, []); 



    useEffect(() => {
        if(companyDetailData){
            setCompanyDetailArrayData([companyDetailData])
        }
    }, [companyDetailData])

    useEffect(() => {
     const scrollBehavior = () => {
            if (collapsibleTableRef.current) {
                collapsibleTableRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        };
            if (directorDetailData || reachDirectorData) {
          scrollBehavior();
        }
    }, [reachDirectorData?.directors, directorDetailData?.data?.directors]);
    
    return (
        <Box component="main" >
            {(loader || loading) && (
                <Box className={classes.loaderContainer}>
                    <LinearProgress className={classes.linearProgress} />
                </Box>
            )}
            <Grid container xs={12} className={classes.rootBox}>
                <FormContainer title={select == "Din" && "DIN Detail" || select == "Company" && "Company Detail" || select == "Director" && "Director Detail"}>
                    <form
                        onSubmit={handleSubmit}
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0rem 1rem",
                        }}
                    >
                        <Grid container spacing={2} style={{ margin: 0 }}>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", }}>
                                <TextField
                                    required
                                    error={!!dinError}
                                    variant="outlined"
                                    helperText={dinError}
                                    className={classes.tokenInput}
                                    name="dinNumber"
                                    size="small"
                                    value={dinNumber || companySearch}
                                    placeholder={select === "Din" ? "ex : 876XXX" : select === "Company" ? "Search Company" : " Search Director"}
                                    onChange={handleChange}
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                                <Grid item>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent={"center"}
                                spacing={2}
                                style={{ margin: 0 }}
                            >
                                <Grid item>
                                    <Grid>
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="position" value={select} onChange={handleRadioChange} defaultValue="Din">
                                                <FormControlLabel
                                                    value="Din"
                                                    control={<Radio color="primary" />}
                                                    label="Din"
                                                    labelPlacement="Right"
                                                />
                                                <FormControlLabel
                                                    value="Company"

                                                    control={<Radio color="primary" />}
                                                    label="Company"
                                                    labelPlacement="Right"
                                                />
                                                <FormControlLabel
                                                    value="Director"

                                                    control={<Radio color="primary" />}

                                                    label="Director"
                                                    labelPlacement="Right"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent={"center"}
                                spacing={2}
                                style={{ margin: 0 }}
                            >

                                <Grid item>

                                    <SubmitButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        disabled={!!loader}
                                        style={{ marginLeft: "1rem" }}
                                        onClick={handleSubmit}
                                    >
                                        {select == "Din" ? "Submit" : "Search"}
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </FormContainer>
            </Grid>
            {select !== "Din" && reachData?.companies ? (
                <>
                    <MuiThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={"Surereach Data"}
                            data={reachData?.companies}
                            columns={searchcolumns}
                            options={{
                                selectableRows: false, onRowClick: handleRowClicked, rowStyle: { cursor: 'pointer' },
                                setRowProps: () => ({
                                    style: {
                                        cursor: 'pointer'
                                    }
                                })

                            }}
                        ></MUIDataTable>
                    </MuiThemeProvider>
                </>
            ):null}

            {data && select == "Din" ? (
                <>
                    {dinDetail && (
                        <MuiThemeProvider theme={getMuiTheme()}>
                            <MUIDataTable
                                title={"DIN Details"}
                                data={rows}
                                columns={columns}
                                options={{ selectableRows: false }}
                            ></MUIDataTable>
                        </MuiThemeProvider>
                    )
                    }
               </>
            ):null}

            {(companyDetailArrayData || reachDirectorData?.directors) && select !== "Din" ?
                <div ref={collapsibleTableRef} >
                <CollapsibleTable
                    headers={headers}
                    data={directorDetailData?.data?.directors || reachDirectorData?.directors || []}
                    detailsData={detailsData}
                    select={select}
                />
            </div>
             :null}

            {companyDetailArrayData && select !== "Din" ?
                <MobileResponsiveTable
                    data={companyDetailData ? companyDetailData : []}
                    select={select}
                />
            :null}
        </Box>
    );
}

export default DinDetail;
