import React from 'react';
import styles from "./table.module.css";

export default function MobileResponsiveTable({ data, select, styleCheack }) {
  console.log("MobileResponsiveTable", data);

  const formatAddress = (addresses) => {
    // Find the registered or present address
    const address = addresses.find(addr =>
      addr.address_type === 'Registered Address' || addr.address_type === 'Present Address'
    ) || addresses[0];

    return address
      ? `${address.street_address}, ${address.city}, ${address.state}, ${address.country} - ${address.postal_code}`
      : 'Address Not Available';
  };

  const companyData = {
    "CIN": data?.identification_number,
    'Company Status': data?.company_status,
    'ROC Name': data?.roc_name,
    'Company Class': data?.class_of_company,
    'Registration Number': data?.registration_number,
    'Company Category': data?.company_subcategory,
    'Company Subcategory': data?.company_subcategory,
    'Date of Incorporation': data?.date_of_incorporation,
    "Activity": data?.main_division_description,
    'Company Address': formatAddress(data?.company_address || []),
    'Authorised Capital': data?.authorised_capital,
    'Paid Up Capital': data?.paid_up_capital,
    'Listing Status': data?.listed_or_not,
    'Date of Last Annual General Meeting': data?.last_agm_date,
    'Date of Latest Balance Sheet': data?.balance_sheet_date
  };

  const handleDownload = () => {
    // Convert the companyData object to a CSV string
    const convertToCSV = (obj) => {
      // Create header row
      const headers = Object.keys(obj);
      const headerRow = headers.join(',');

      // Create data row
      const dataRow = headers.map(header => {
        // Ensure values are properly escaped for CSV
        let value = obj[header] || '';
        value = value.toString().replace(/"/g, '""'); // Escape double quotes

        // Wrap in quotes if value contains comma, newline, or quote
        if (/[",\n]/.test(value)) {
          value = `"${value}"`;
        }

        return value;
      }).join(',');

      return `${headerRow}\n${dataRow}`;
    };

    // Generate CSV content
    const csvContent = convertToCSV(companyData);

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Set link attributes
      link.setAttribute('href', url);
      link.setAttribute('download', 'company_information.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the URL object
      URL.revokeObjectURL(url);
    }
  };

  return React.createElement(
    'div',
    { className:  (select="Director" && styleCheack ) ?styles.containerdir:styles.container},
    React.createElement(
      'div',
      { className: styles.header },
      React.createElement('h1', { className: styles.title }, 'Company Information'),
      React.createElement(
        'button',
        { onClick: handleDownload, className: styles.downloadButton },
         // Download svg use for future
        // React.createElement(
        //   'svg',
        //   {
        //     width: '16',
        //     height: '16',
        //     viewBox: '0 0 24 24',
        //     fill: 'none',
        //     stroke: 'currentColor',
        //     strokeWidth: '2',
        //     strokeLinecap: 'round',
        //     strokeLinejoin: 'round',
        //   },
        //   React.createElement('path', { d: 'M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4' }),
        //   React.createElement('polyline', { points: '7 10 12 15 17 10' }),
        //   React.createElement('line', { x1: '12', y1: '15', x2: '12', y2: '3' })
        // ),
        'Download CSV'
      )
    ),
    React.createElement(
      'div',
      { className: styles.grid },
      React.createElement(
        'div',
        { className: styles.card },
        Object.entries(companyData)
          .slice(0, Math.ceil(Object.entries(companyData).length / 2))
          .map(function ([key, value]) {
            return React.createElement(
              'div',
              { key: key, className: styles.item },
              React.createElement('span', { className: styles.label }, key),
              React.createElement('span', { className: styles.value }, value)
            );
          })
      ),
      React.createElement(
        'div',
        { className: styles.card },
        Object.entries(companyData)
          .slice(Math.ceil(Object.entries(companyData).length / 2))
          .map(function ([key, value]) {
            return React.createElement(
              'div',
              { key: key, className: styles.item },
              React.createElement('span', { className: styles.label }, key),
              React.createElement('span', { className: styles.value }, value)
            );
          })
      )
    )
  );
}
